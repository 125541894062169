// src/ContactUs.js

import React, { useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Helmet } from 'react-helmet';
import './App.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    name: '',
    service: 'Software Development Services',
    message: '',
    referralEmail: '' // Change to referralEmail
  });

  const [status, setStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const functions = getFunctions();
    const sendContactEmail = httpsCallable(functions, 'sendContactEmail');

    try {
      const result = await sendContactEmail(formData);
      if (result.data && result.data.success) {
        setStatus('Form submitted successfully!');
        setFormData({
          email: '',
          phone: '',
          name: '',
          service: 'Software Development Services',
          message: '',
          referralEmail: '' // Reset referralEmail
        });
      } else {
        setStatus(`Failed to submit form: ${result.data ? result.data.error : 'No response from server'}`);
      }
    } catch (error) {
      setStatus(`Failed to submit form: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="page-box">
      <Helmet>
        <title>Human Touch Labs - Web Development & Software Solutions</title>
        <meta name="description" content="Human Touch Labs - A Chicago Based Web Development and Software Development Firm; Specialist in Refined and Rapid Web and Software Development." />
        <meta name="keywords" content="Human Touch Labs, Web Designers, Web Developers, Software Developers, Web Design, Website Creation, Web Host Management, Web Integrations, Website Maintenance, Domain Management, Search Engine Optimization, Game Website Creation, User Account Management, Graphic Design, Custom Enterprise Software, Small Software Solutions, Digital Logistics, Software Integrations, Software Diagnostics, Software Optimization, Testing Tool Creation, Software Automation, Game Development Tools, Chicago, htl" />
        <meta name="robots" content="index" />
      </Helmet>
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone (Optional):</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Full Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="referralEmail">Referral Email (Optional):</label>
          <input
            type="email"
            id="referralEmail"
            name="referralEmail"
            value={formData.referralEmail}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="service">Service:</label>
          <select
            id="service"
            name="service"
            value={formData.service}
            onChange={handleChange}
            required
          >
            <option value="Software Development Services">Software Development Services</option>
            <option value="Web Development Services">Web Development Services</option>
            <option value="Products">Products</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        {status && <p>{status}</p>}
      </form>
    </div>
  );
};

export default ContactUs;
