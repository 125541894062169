// src/Careers.js

import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';



function Careers() {

  return (

    <div className="page-box">
      <Helmet>
        <title>Human Touch Labs - Web Development & Software Solutions</title>
        <meta name="description" content="Human Touch Labs - A Chicago Based Web Development and Software Development Firm; Specialist in Refined and Rapid Web and Software Development." />
        <meta name="keywords" content="Human Touch Labs, Web Designers, Web Developers, Software Developers, Web Design, Website Creation, Web Host Management, Web Integrations, Website Maintenance, Domain Management, Search Engine Optimization, Game Website Creation, User Account Management, Graphic Design, Custom Enterprise Software, Small Software Solutions, Digital Logistics, Software Integrations, Software Diagnostics, Software Optimization, Testing Tool Creation, Software Automation, Game Development Tools, Chicago, htl" />
        <meta name="robots" content="index" />
      </Helmet>
      <h2>Careers</h2>

      <p>Careers will be posted here once we are ready to expand our Team!</p>

      <p>Candidates that wish to be considered first once HTL is ready to hire can send their resumes and portfolios here: <a href="mailto:contact@humantouchlabs.com" className="plain-link">contact@humantouchlabs.com</a></p>

    </div>

  );

}



export default Careers;

