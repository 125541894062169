// src/SoftwareDevelopment.js

import React from 'react';

import { Helmet } from 'react-helmet';

import { useNavigate } from 'react-router-dom';

import './App.css';



function SoftwareDevelopment() {

  const navigate = useNavigate();



  const handleContactClick = () => {

    navigate('/contact');

  };



  const handleServicesClick = () => {

    navigate('/services');

  };



  return (

    <div className="page-box">
      <Helmet>
        <title>Human Touch Labs - Web Development & Software Solutions</title>
        <meta name="description" content="Human Touch Labs - A Chicago Based Web Development and Software Development Firm; Specialist in Refined and Rapid Web and Software Development." />
        <meta name="keywords" content="Human Touch Labs, Web Designers, Web Developers, Software Developers, Web Design, Website Creation, Web Host Management, Web Integrations, Website Maintenance, Domain Management, Search Engine Optimization, Game Website Creation, User Account Management, Graphic Design, Custom Enterprise Software, Small Software Solutions, Digital Logistics, Software Integrations, Software Diagnostics, Software Optimization, Testing Tool Creation, Software Automation, Game Development Tools, Chicago, htl" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <h2>Software Development</h2>

      <p>

        At Human Touch Labs, our Software Development team is dedicated to creating innovative software solutions that cater to the unique needs of our clients. Our team of skilled developers is experienced in a wide range of technologies and industries, allowing us to deliver custom solutions that drive efficiency and growth.

      </p>

      <p>

        Whether you need enterprise software, small software solutions, or specialized tools for your business, we have the expertise to bring your vision to life. Our services include custom software solutions, software integrations, software diagnostics, optimization, and more.

      </p>

      <p>

        We also offer comprehensive testing and automation services to ensure your software operates smoothly and efficiently. Our team can create custom testing tools and implement software automation to streamline your processes.

      </p>

      <p>

        Additionally, we provide game development tools and digital logistics solutions to meet the evolving demands of the industry. Our goal is to deliver high-quality, reliable software that exceeds your expectations.

      </p>

      <p>

        Contact us below to learn more about how we can help you achieve your software development goals.

      </p>

      <div className="button-container">

        <button className="services-button" onClick={handleServicesClick}>

          See Our Services

        </button>

        <button className="contact-button" onClick={handleContactClick}>

          Contact Us Here

        </button>

      </div>

    </div>

  );

}



export default SoftwareDevelopment;

