// src/About.js
import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';

function About() {
  return (
    <div className="page-box">
      <Helmet>
        <title>Human Touch Labs - Web Development & Software Solutions</title>
        <meta name="description" content="Human Touch Labs - A Chicago Based Web Development and Software Development Firm; Specialist in Refined and Rapid Web and Software Development." />
        <meta name="keywords" content="Human Touch Labs, Web Designers, Web Developers, Software Developers, Web Design, Website Creation, Web Host Management, Web Integrations, Website Maintenance, Domain Management, Search Engine Optimization, Game Website Creation, User Account Management, Graphic Design, Custom Enterprise Software, Small Software Solutions, Digital Logistics, Software Integrations, Software Diagnostics, Software Optimization, Testing Tool Creation, Software Automation, Game Development Tools, Chicago, htl" />
        <meta name="robots" content="index" />
      </Helmet>
      <h2>About Us</h2>
      <p>
        Human Touch Labs was founded in 2024 by Inventor Andrew Fey after in-depth analysis and observations of the trends in the Tech Industry revealed another era of Innovation is here for us humans to seize. Human Touch Labs is here to help bridge everyday businesses, start-ups, and more enter the future of technology and help rapidly modernize their Web Presence and Tech Stack used at a fraction of the cost compared to traditional companies.
      </p>
      <p>
        The Human Touch Labs Team consists of Tech Industry professionals who have successfully excelled in their fields as Web Developers, Designers, Game Developers, and Software Engineers before joining HTL. By introducing and training these experienced developers in Prompt Engineering, Internal Tools, and other tools such as Google Cloud Platform; we have created a team capable of rapidly creating functional, optimized, and polished deliverables to clients within a fraction of the time and cost of most competitors.
      </p>
      <p>
        At Human Touch Labs; we strive to improve our internal tools daily and also work on bringing you future products to help integrate new technology into your small business and life with ease; knowing you have a quality product and do not have to pay a luxury price for it.
      </p>
      <p>
        To find out more about some of our Clients who are happy to let us share our work for them and other projects we are involved in; go and check out Our Projects!
      </p>
    </div>
  );
}

export default About;
