// src/App.js



import React, { useEffect, useState } from 'react';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import Home from './Home';

import About from './About';

import WebDevelopment from './WebDevelopment';

import SoftwareDevelopment from './SoftwareDevelopment';

import OurProjects from './OurProjects';

import ContactUs from './ContactUs';

import Careers from './Careers';

import Services from './Services';

import ControlPanel from './ControlPanel'; 

import Blogs from './Blogs';

import FAQ from './FAQ'; 

import htllogo from './htllogo.PNG';

import './App.css';



function App() {

  const [isMobile, setIsMobile] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);



  useEffect(() => {

    const checkMobile = () => setIsMobile(window.innerWidth <= 768);

    checkMobile();

    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);

  }, []);



  useEffect(() => {

    document.body.style.overflowY = isMobile && menuOpen ? 'hidden' : 'auto';

  }, [isMobile, menuOpen]);



  const toggleMenu = () => setMenuOpen(!menuOpen);



  return (

    <Router>

      <div className="App">

        <header className="App-header">

          <div className="logo-container">

            <img src={htllogo} alt="HTL Logo" className="logo" />

            <h1>

              <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>

                Welcome to Human Touch Labs

              </Link>

            </h1>

          </div>

          <nav>

            <button className="menu-toggle" onClick={toggleMenu}>

              &#9776; {/* Hamburger icon */}

            </button>

            <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>

              <li><b><Link to="/" onClick={toggleMenu}>Home</Link></b></li>

              <li><b><Link to="/services" onClick={toggleMenu}>Services</Link></b></li>

              <li><b><Link to="/about" onClick={toggleMenu}>About Us</Link></b></li>

              <li><b><Link to="/contact" onClick={toggleMenu}>Contact Us</Link></b></li>

              <li><b><Link to="/our-projects" onClick={toggleMenu}>Our Projects</Link></b></li>

              <li><b><Link to="/blog" onClick={toggleMenu}>Blog</Link></b></li>

              <li><b><Link to="/careers" onClick={toggleMenu}>Careers</Link></b></li>

              <li><b><Link to="/faq" onClick={toggleMenu}>FAQ</Link></b></li> 

            </ul>

          </nav>

        </header>

        <main className="App-content">

          <Routes>

            <Route path="/" element={<Home />} />

            <Route path="/services" element={<Services />} />

            <Route path="/web-development" element={<WebDevelopment />} />

            <Route path="/software-development" element={<SoftwareDevelopment />} />

            <Route path="/our-projects" element={<OurProjects />} />

            <Route path="/about" element={<About />} />

            <Route path="/contact" element={<ContactUs />} />

            <Route path="/careers" element={<Careers />} />

            <Route path="/faq" element={<FAQ />} /> 

            <Route path="/blog" element={<Blogs />} />

            <Route path="/controlpanel" element={<ControlPanel />} /> 

          </Routes>

        </main>

        <div className="rights-reserved">Rights Reserved 2024 by Human Touch Labs</div>

      </div>

    </Router>

  );

}



export default App;

