import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';

const BlogContent = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false); // Restored add new blog form
  const [editingBlogId, setEditingBlogId] = useState(null);
  const [editBlog, setEditBlog] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [newBlog, setNewBlog] = useState({
    title: '',
    date: '',
    paragraphs: [''],
  });

  const db = getFirestore();

  // Fetch blogs from Firestore
  const fetchBlogs = async () => {
    const blogsCollection = collection(db, 'blogs');
    const blogSnapshot = await getDocs(blogsCollection);

    if (blogSnapshot.empty) {
      setBlogs([]); // No blogs available
    } else {
      const blogList = blogSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(blog => blog.date && blog.title)
        .sort((a, b) => new Date(b.date) - new Date(a.date));

      setBlogs(blogList);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchBlogs();
  }, [db]);

  // Add a new paragraph to the new blog
  const handleAddParagraph = () => {
    setNewBlog(prevBlog => ({
      ...prevBlog,
      paragraphs: [...prevBlog.paragraphs, '']
    }));
  };

  // Handle changes in new blog fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewBlog(prevBlog => ({
      ...prevBlog,
      [name]: value,
    }));
  };

  // Handle paragraph changes for the new blog
  const handleParagraphChange = (e, index) => {
    const updatedParagraphs = [...newBlog.paragraphs];
    updatedParagraphs[index] = e.target.value;
    setNewBlog(prevBlog => ({
      ...prevBlog,
      paragraphs: updatedParagraphs
    }));
  };

  // Submit the new blog to Firestore
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const blogsCollection = collection(db, 'blogs');
      await addDoc(blogsCollection, newBlog);

      // Reset form state and reload blog list
      setNewBlog({
        title: '',
        date: '',
        paragraphs: [''],
      });
      setShowForm(false);
      fetchBlogs(); // Reload blogs after submission
    } catch (error) {
      console.error('Error adding blog: ', error);
    }
  };

  // Handle entering edit mode
  const handleEdit = (blog) => {
    setEditingBlogId(blog.id);
    setEditBlog({ ...blog });
  };

  // Handle changing the blog being edited
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditBlog(prev => ({ ...prev, [name]: value }));
  };

  // Handle paragraph change in edit mode
  const handleEditParagraphChange = (e, index) => {
    const updatedParagraphs = [...editBlog.paragraphs];
    updatedParagraphs[index] = e.target.value;
    setEditBlog(prev => ({ ...prev, paragraphs: updatedParagraphs }));
  };

  // Save edited blog to Firestore
  const handleSubmitEdit = async () => {
    try {
      const blogDocRef = doc(db, 'blogs', editingBlogId);
      await updateDoc(blogDocRef, editBlog);

      setEditingBlogId(null); // Exit edit mode
      fetchBlogs(); // Reload blogs after edit
    } catch (error) {
      console.error('Error editing blog: ', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingBlogId(null); // Exit edit mode without saving
  };

  // Handle delete blog
  const handleDelete = async (blogId) => {
    try {
      await deleteDoc(doc(db, 'blogs', blogId));
      setConfirmDelete(null); // Reset the confirm delete state
      fetchBlogs(); // Reload blogs after deletion
    } catch (error) {
      console.error('Error deleting blog: ', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="blog-content">
      <button onClick={() => setShowForm(!showForm)}>{showForm ? 'Cancel' : 'Add New Blog'}</button>

      {showForm && (
        <form onSubmit={handleSubmit}>
          <h3>Create New Blog</h3>
          <label>
            Title:
            <input type="text" name="title" value={newBlog.title} onChange={handleChange} required />
          </label>
          <br />
          <label>
            Date (MM/DD/YYYY):
            <input type="text" name="date" value={newBlog.date} onChange={handleChange} placeholder="MM/DD/YYYY" required />
          </label>
          <br />
          <h4>Paragraphs:</h4>
          {newBlog.paragraphs.map((paragraph, index) => (
            <div key={index}>
              <textarea
                value={paragraph}
                onChange={(e) => handleParagraphChange(e, index)}
                placeholder={`Paragraph ${index + 1}`}
                required
              />
              <br />
            </div>
          ))}
          <button type="button" onClick={handleAddParagraph}>+ Add Paragraph</button>
          <br />
          <button type="submit">Submit Blog</button>
        </form>
      )}

      {blogs.length === 0 ? (
        <p>No Blogs Available</p>
      ) : (
        blogs.map((blog) => (
          <div key={blog.id} className="blog-post">
            {editingBlogId === blog.id ? (
              // Edit mode
              <div>
                <input
                  type="text"
                  name="title"
                  value={editBlog.title}
                  onChange={handleEditChange}
                  required
                />
                <input
                  type="text"
                  name="date"
                  value={editBlog.date}
                  onChange={handleEditChange}
                  required
                />
                <h4>Edit Paragraphs:</h4>
                {editBlog.paragraphs.map((paragraph, index) => (
                  <div key={index}>
                    <textarea
                      value={paragraph}
                      onChange={(e) => handleEditParagraphChange(e, index)}
                      required
                    />
                  </div>
                ))}
                <button onClick={handleCancelEdit}>Cancel</button>
                <button onClick={handleSubmitEdit}>Submit</button>
              </div>
            ) : (
              // View mode
              <div>
                <h2>{blog.title}</h2>
                <p><strong>Date:</strong> {blog.date}</p>
                {blog.paragraphs.map((paragraph, idx) => (
                  <p key={idx}>{paragraph}</p>
                ))}
                <button onClick={() => handleEdit(blog)}>Edit</button>

                {/* Delete button instead of red X */}
                <button
                  style={{ color: 'red', cursor: 'pointer', marginLeft: '10px' }}
                  onClick={() => setConfirmDelete(blog.id === confirmDelete ? null : blog.id)}
                >
                  Delete
                </button>

                {confirmDelete === blog.id && (
                  <span style={{ marginLeft: '10px' }}>
                    Proceed with Delete?
                    <button
                      style={{ marginLeft: '5px', color: 'red', cursor: 'pointer' }}
                      onClick={() => handleDelete(blog.id)}
                    >
                      Confirm
                    </button>
                  </span>
                )}
              </div>
            )}
            <hr />
          </div>
        ))
      )}
    </div>
  );
};

export default BlogContent;
