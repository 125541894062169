// src/Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import './App.css';

function Home() {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <div className="Home">
      <Helmet>
        <title>Human Touch Labs - Web Development & Software Solutions</title>
        <meta name="description" content="Human Touch Labs - A Chicago Based Web Development and Software Development Firm; Specialist in Refined and Rapid Web and Software Development." />
        <meta name="keywords" content="Human Touch Labs, Web Designers, Web Developers, Software Developers, Web Design, Website Creation, Web Host Management, Web Integrations, Website Maintenance, Domain Management, Search Engine Optimization, Game Website Creation, User Account Management, Graphic Design, Custom Enterprise Software, Small Software Solutions, Digital Logistics, Software Integrations, Software Diagnostics, Software Optimization, Testing Tool Creation, Software Automation, Game Development Tools, Chicago, htl" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="home-box-container">
        <div className="home-box" onClick={() => handleClick('/web-development')}>
          <h2>Web Development</h2>
          <p>
            Human Touch Lab’s Web Designers and Web Developers are trained to help make you your dream website using our advanced tools, helping keep the time and cost below the competition and more in your pocket.
          </p>
          <p>
            Do you want to set up a simple landing page? We can help with that! Want to set up an entire shopping catalog you can modify with ease and allow customers to purchase directly from your site? We can also help with that! At HTL, We are excited to make any Custom Module, simple or advanced, for your site.
          </p>
          <li>Simple Sites for Small Businesses start at $600;</li>
            <ul>
              <li>Homepage + 2 Formatted Pages + Custom Site Module.</li>
            </ul>
          <li>Professional Business and Gallery Sites start at $1200;</li>
            <ul>
              <li>Homepage + 5 Formatted Pages + Avalible Module Templates for Formatted Pages + 2 Custom Site Modules.</li>
            </ul>
          <li>Revision Hours are $60 per hour and each Site Package comes with some free Revision Hours!</li>
          <p>
          **Note We Do Not Endorse AI Art at Human Touch Labs and offer Graphic Design Services as needed.
          </p>
        </div>
        <div className="home-box" onClick={() => handleClick('/software-development')}>
          <h2>Software Development</h2>
          <p>
            Human Touch Lab’s Software Development Team are experienced and tested developers with the ability to create any software they would be asked to create. Using our in-house and 3rd party tools; we can deliver a software solution to any challenge.
          </p>
          <p>
            Since HTL has an advanced workflow allowing for Rapid Development, a perk Clients will see is fast turn around times on everything from prototypes to production ready software and applications.
          </p>
          <p>
            Some of the Software Projects our Team is happy and excited to take on along with the prices are below.
            <li>IOS-Android App Development starting at $80 an hour.</li>
            <li>Prototype Software starting at $100 an hour.</li>
            <li>System Integrations and Upgrades starting at $110 an hour.</li>
            <li>Server Backend Development starting at $110 an hour.</li>
          </p>
          <p>
            Our Software Team believes in delivering Software with a purpose and that purpose will always be fulfilled with Human Touch Labs.
          </p>
        </div>
        <div className="home-box" onClick={() => handleClick('/our-projects')}>
          <h2>Our Projects</h2>
          <p>
            At Human Touch Labs, we pride ourselves on the diverse range of projects we have undertaken. From web applications to mobile apps, our team has consistently delivered top-notch solutions. 
          </p>
          <p>
            The Team at HTL comes almost exclusively from the Game Development Industry, and you will see that HTL is also Excited and Ready to take on any game related project a Client has! You can see one Game Studio is already an HTL Client.
          </p>
          <p>
            Our portfolio showcases our commitment to innovation and excellence, with each project tailored to meet the unique needs of our Clients, ensuring their utmost satisfaction. 
          </p>
          <p>
            Furthermore, Human Touch Labs is excited to only grow the projects we work on and the connections we make with Clients. We offer prospective Clients a host of discounts and a referral program; find our more about both in our FAQ! 
          </p>
          <p>
            Come in and see what Human Touch Labs is proud to present!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
