// src/Services.js

import React from 'react';

import { Helmet } from 'react-helmet';

import './App.css';



function Services() {

  return (

    <div className="services-container">
      <Helmet>
        <title>Human Touch Labs - Web Development & Software Solutions</title>
        <meta name="description" content="Human Touch Labs - A Chicago Based Web Development and Software Development Firm; Specialist in Refined and Rapid Web and Software Development." />
        <meta name="keywords" content="Human Touch Labs, Web Designers, Web Developers, Software Developers, Web Design, Website Creation, Web Host Management, Web Integrations, Website Maintenance, Domain Management, Search Engine Optimization, Game Website Creation, User Account Management, Graphic Design, Custom Enterprise Software, Small Software Solutions, Digital Logistics, Software Integrations, Software Diagnostics, Software Optimization, Testing Tool Creation, Software Automation, Game Development Tools, Chicago, htl" />
        <meta name="robots" content="index" />
      </Helmet>

      <div className="page-box">

        <h2>Web Development</h2>

        <ul>

          <li>Web Design</li>

          <li>Website Creation</li>

          <li>Web Host Management</li>

          <li>Web Host Migration</li>

          <li>Web Integrations</li>

          <li>Website Maintenance</li>

          <li>Domain Management</li>

          <li>Search Engine Optimization</li>

          <li>Game Website Creation</li>

          <li>User Account Management</li>

          <li>Graphic Design</li>

        </ul>

      </div>

      <div className="page-box">

        <h2>Software Development</h2>

        <ul>

          <li>Custom Enterprise Software</li>

          <li>Android and IOS Application Development</li>

          <li>Software Solutions</li>

          <li>Small Software Solutions</li>

          <li>Digital Logistics</li>

          <li>Software Integrations</li>

          <li>Software Diagnostics</li>

          <li>Software Optimization</li>

          <li>Testing Tool Creation</li>

          <li>Software Automation</li>

          <li>Game Development Tools</li>

        </ul>

      </div>

    </div>

  );

}



export default Services;

