// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyDy9zRQSVz_6wIIR3XTq2gfkoKPWzHK1qE',
  authDomain: 'humantouchlabs.firebaseapp.com',
  projectId: 'humantouchlabs',
  storageBucket: 'humantouchlabs.appspot.com',
  messagingSenderId: '965789570336',
  appId: '1:965789570336:web:1bbbe5d9011812f3dd3345',
  measurementId: 'G-XYNFFR08J7'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { db, functions };
