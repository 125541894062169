// src/Blogs.js
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './App.css'; // Assuming your CSS styles are in App.css

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const db = getFirestore();

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogsCollection = collection(db, 'blogs');
      const blogSnapshot = await getDocs(blogsCollection);

      if (blogSnapshot.empty) {
        setBlogs([]); // No blogs available
      } else {
        const blogList = blogSnapshot.docs
          .map(doc => doc.data())
          .filter(blog => blog.date && blog.title) // Ensure blogs have both a date and title
          .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date (newest first)
        
        setBlogs(blogList);
      }

      setLoading(false);
    };

    fetchBlogs();
  }, [db]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      {blogs.length === 0 ? (
        <p>No Blogs Available</p>
      ) : (
        blogs.map((blog, index) => (
          <div key={index} className="blog-page-box">
            <h3>{blog.title}</h3>
            <p><strong>Date:</strong> {blog.date}</p>
            {blog.paragraphs.map((paragraph, idx) => (
              <p key={idx}>{paragraph}</p>
            ))}
          </div>
        ))
      )}
    </div>
  );
};

export default Blogs;
