// src/FAQ.js



import React, { useState } from 'react';

import { Helmet } from 'react-helmet';

import './App.css';



const FAQItem = ({ question, answer }) => {

  const [isOpen, setIsOpen] = useState(false);



  const toggleOpen = () => {

    setIsOpen(!isOpen);

  };



  return (

    <div className="faq-item">

      <div className="faq-question" onClick={toggleOpen}>

        <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>

        <h3>{question}</h3>

      </div>

      {isOpen && <div className="faq-answer"><p>{answer}</p></div>}

    </div>

  );

};



const FAQ = () => {

  const faqItems = [

    {

      question: 'Why Will HTL Not Use AI-Generated Art?',

      answer: 'Simple, AI-Generated Art is lifeless and bland on top of the models being trained on stolen art! HTL will never use or accept Client use of AI-Generated Art in Site Creation of Modification. HTL gladly offers Graphic Desginer Services starting at $35 an hour.'

    },

    {

      question: 'What is the HTL Referral Program?',

      answer: 'HTL offers anyone who knows about us and tells others about us an opprotunity to earn with us! There is no need to have been an HTL Client to participate as a Referrer in the HTL Referral Program, the program is open to anyone in the United States and US Territories 18 years of age and older. When a Referred Client spends $600 or more with HTL, the Referrer will earn $50 and can earn $50 more incrementally with every further $600 increment spent by the Client on the site or software creation for the Client! Once HTL has completed the site or software and been fully compensated by a Client for a site or software that provided a Referral E-mail, the Referrer will be contacted by HTL within 5 to 7 Days where they will be offered the option of PayPal, Mailed Check, and Cash(If Referrer Wishes To Travel To Our Office). TERMS: Participants in the HTL Referral Program must be 18 years of age or older. The HTL Referral Program is Restricted to those in the US and US Territories. All Referral Information must be provided by the Client to HTL before site construction starts to be considered a valid Referral for the Referrer. Use of the Contact Us Referral Email section is not necessary to inform HTL of your Referral from a Referrer, HTL will accept a Client directly informing us of who referred them. A Referral E-mail must also include indetifiable or whole parts of the Legal Name of the Referrer to ensure legitamacy. The Referral Program does not extend to Services rendered on a completed sites and softwares by HTL after initial site or software completion. Payment of Referral Bonus method negotiable if the three previously mentioned options are unavalible to the Referrer.'

    },

    {

      question: 'What Discounts Does HTL Offer?',

      answer: 'HTL is glad to offer Discounts where we believe they are needed! HTL gladly offers Veterans a $100 discount on Site and Software Creation services. HTL is also proud to support Pilsen businesses by offering a $100 discount to them as well. HTL also offers Community Serving Non-Profits Web Development Services at a $250 discount on Simple Site packages and over.'

    },

    {

      question: 'Why Does HTL Have A Simple Site Layout?',

      answer: 'Simple, HTL choose to make a responsive, fast, secure, and intuitive site with a simple layout to help any vistor navigate the site regardless of their usual computer usage, given some small buisness owners HTL will reach out too never even use a computer outside of business related tasks. Our site also is extremely dynamic in how it renders, allowing for our site to be viewed on any platform.'

    },

    {

      question: 'How Does HTL Make Websites and Software Rapidly?',

      answer: 'HTL uses advanced in-house and 3rd party engineering tools to assist our Designers and Developers in their day-to-day work. Some of these tools can be anything from GitLab for Team Workspaces to AI-Assited Tools for helping our Developers.'

    },

    {

      question: 'How Does HTL Make Apps For Both IOS and Android Concurrently?',

      answer: 'HTL is able to develop cross-platform applications thanks to an amazing Google Tool called Google Flutter mixed with our in house tool use.'

    },

    {

      question: 'Is HTL Making A Game?',

      answer: 'While our Team is made up of Game Industry professionals, we are only making a game as a Team Hobby/After Hours thing. Once it is finished, we will release it however!'

    },

    {

      question: 'How Can I Contact HTL?',

      answer: 'You can contact us via the Contact Us page on our website or by emailing contact@humantouchlabs.com directly.'

    },

    // Add more FAQ items as needed

  ];



  return (

    <div className="page-box">
      <Helmet>
        <title>Human Touch Labs - Web Development & Software Solutions</title>
        <meta name="description" content="Human Touch Labs - A Chicago Based Web Development and Software Development Firm; Specialist in Refined and Rapid Web and Software Development." />
        <meta name="keywords" content="Human Touch Labs, Web Designers, Web Developers, Software Developers, Web Design, Website Creation, Web Host Management, Web Integrations, Website Maintenance, Domain Management, Search Engine Optimization, Game Website Creation, User Account Management, Graphic Design, Custom Enterprise Software, Small Software Solutions, Digital Logistics, Software Integrations, Software Diagnostics, Software Optimization, Testing Tool Creation, Software Automation, Game Development Tools, Chicago, htl" />
        <meta name="robots" content="index" />
      </Helmet>

      <h2>Frequently Asked Questions</h2>

      {faqItems.map((item, index) => (

        <FAQItem key={index} question={item.question} answer={item.answer} />

      ))}

    </div>

  );

};



export default FAQ;

