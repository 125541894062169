// src/GoogleSignIn.js

import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import BlogContent from './BlogContent.js';
import './App.css';

const GoogleSignIn = () => {
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showBlogContent, setShowBlogContent] = useState(false); // State to handle Blog Content visibility
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const userDoc = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userDoc);

        if (!userSnapshot.exists()) {
          await setDoc(userDoc, {
            uid: user.uid,
            email: user.email,
            firstName: user.displayName.split(' ')[0],
          });
          setIsAdmin(false);
        } else {
          const userData = userSnapshot.data();
          if (userData.role === 'Admin') {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Error during sign-out', error);
    }
  };

  // Toggle visibility of Blog Content
  const toggleBlogContent = () => {
    setShowBlogContent(!showBlogContent);
  };

  return (
    <div className="google-signin">
      {user ? (
        <div>
          <p>Welcome, {user.displayName.split(' ')[0]}!</p>
          {isAdmin ? (
            <div>
              <p>You are an Admin. You have access to restricted data.</p>
              
              {/* Blog Content Collapsible Section */}
              <div className="collapsible-section">
                <div className="collapsible-title" onClick={toggleBlogContent}>
                  <h3>Blog Content</h3>
                  <span className={`arrow ${showBlogContent ? 'open' : ''}`}>&#9660;</span>
                </div>
                {showBlogContent && <BlogContent />}
              </div>

            </div>
          ) : (
            <p>You are not an Admin. Limited access.</p>
          )}
          <button onClick={handleSignOut}>Logout</button>
        </div>
      ) : (
        <button onClick={handleSignIn}>Sign In with Google</button>
      )}
    </div>
  );
};

export default GoogleSignIn;
