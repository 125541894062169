// src/WebDevelopment.js

import React from 'react';

import { Helmet } from 'react-helmet';

import { useHref, useNavigate } from 'react-router-dom';

import './App.css';



function WebDevelopment() {

  const navigate = useNavigate();



  const handleContactClick = () => {

    navigate('/contact');

  };



  const handleServicesClick = () => {

    navigate('/services');

  };

  const handleProjectsClick = () => {
    navigate('/our-projects')
  }


  return (

    <div className="page-box">
      <Helmet>
        <title>Human Touch Labs - Web Development & Software Solutions</title>
        <meta name="description" content="Human Touch Labs - A Chicago Based Web Development and Software Development Firm; Specialist in Refined and Rapid Web and Software Development." />
        <meta name="keywords" content="Human Touch Labs, Web Designers, Web Developers, Software Developers, Web Design, Website Creation, Web Host Management, Web Integrations, Website Maintenance, Domain Management, Search Engine Optimization, Game Website Creation, User Account Management, Graphic Design, Custom Enterprise Software, Small Software Solutions, Digital Logistics, Software Integrations, Software Diagnostics, Software Optimization, Testing Tool Creation, Software Automation, Game Development Tools, Chicago, htl" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <h2>Web Development</h2>

      <p>

        At Human Touch Labs; our small team has decades of combined experience in the Web Development and Web Design industry. Our Developers and Designers have been working on websites since the 00s and have continued to be innovators in the industry.

      </p>

      <p>

        Once a Client is confident in a web layout, our Web Developers and Designers will continue to build out the site and add the requested functionality and security. The developer will also be able to create custom admin control tools or other adaptive access tools fit for any business such as email services to modify site information and postings for the business owner who feels an admin control panel is too much. Our developers can integrate the old and new to deliver a modern and sleek website.

      </p>

      <p>

        HTL also would be happy to help Client's with exisitng sites migrate away from their current Monthly Maintance Fees, giving our client's true control and ownerhsip of their websites! Feel free to inquire.

      </p>

      <p>

        We also offer Search Engine Optimization services and Social Media Automation integrations for your businesses as well.

      </p>

      <p>

        Feel free to check out Our Projects to see some of our Web Developer's and Designer's work!

      </p>

      <p>

        Contact us below to get started with brightening up your online presence!

      </p>

      <div className="button-container">

        <button className="services-button" onClick={handleServicesClick}>

          See Our Services

        </button>

        <button className="contact-button" onClick={handleContactClick}>

          Contact Us Here

        </button>

      </div>

    </div>

  );

}



export default WebDevelopment;

