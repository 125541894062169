// src/ControlPanel.js
import React from 'react';
import GoogleSignIn from './GoogleSignIn';
import './App.css';

const ControlPanel = () => {
  return (
    <div className="page-box">
      <h2>Control Panel</h2>
      <GoogleSignIn />
    </div>
  );
};

export default ControlPanel;
